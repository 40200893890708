var map;
var artdent = new google.maps.LatLng(48.285457, 17.2715263); //SURADNICE

var MY_MAPTYPE_ID = 'custom_style';

function initialize() {

  var featureOpts = [
      {
        featureType: "landscape",
        stylers: [
            { color: "#000" }
        ]
    },
    {
      elementType: 'labels', // POPISKY CIEST
      stylers: [
        { visibility: 'on' }
      ]
    },
    {
      featureType: 'water', // VODA
      stylers: [
        { color: '#000' }
      ]
    },
    {
        featureType: 'road.local', // CESTY
        elementType: 'geometry.fill',
        stylers: [
          { color: '#000' },
          { weight: 5 } // SIRKA CIEST
        ]
    },
    {
        featureType: 'road.arterial', // Hlavne cesty
        stylers: [
          { color: '#000' },
          { weight: 7 } // SIRKA CIEST
        ]
    },
    {
        featureType: 'road.highway', // dialnice
        stylers: [
          { color: '#fff' },
          { weight: 5 } // SIRKA CIEST
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.text', // STROKE alebo FILL
        stylers: [
            { color: '#fff' },
            { weight: 0.1 } // SIRKA pisma
        ]
    }
  ];

  var mapOptions = {
    zoom: 18,
    center: artdent,
    scrollwheel: false,
    mapTypeControlOptions: {
      mapTypeIds: [google.maps.MapTypeId.ROADMAP, MY_MAPTYPE_ID]
    },
    mapTypeId: MY_MAPTYPE_ID
  };

  map = new google.maps.Map(document.getElementById('map_canvas'),mapOptions);


  var styledMapOptions = {
    name: 'ZOB'
  };


  var contentString = '<div style="width:320px;height:auto;padding:5px;font-size:15px;line-height:20px;"><img style="float:left" width="120px"  height="120px" src="../images/logo.png" width="150" height="150"><br /><span>Kollarova 12,<br> Pezinok 902 01,<br> Slovakia<br> +421(0)904270235<br></span></div>';
  var infowindow = new google.maps.InfoWindow({
        content: contentString,
        backgroundColor: 'rgb(255,0,0)',
        arrowStyle: 1,
        map: map
  });

  var image = '';
    var marker = new google.maps.Marker({
      position: artdent,
      map: map,
      icon: image
    });




  var customMapType = new google.maps.StyledMapType(featureOpts, styledMapOptions);

  map.mapTypes.set(MY_MAPTYPE_ID, customMapType);
  google.maps.event.addListener(marker, 'click', function() {
        infowindow.open(map,marker);
  });
  google.maps.event.trigger(marker, "click");
}

google.maps.event.addDomListener(window, 'load', initialize);
