var Site = {
  init: function(){
    $('.burger-menu').on('click', function(){
      $(this).toggleClass('active');
      $('.menu').toggleShow(200);
    })



    var owl = $('.daily-carousel-group').owlCarousel({
        items: 1,
    });

    $('.carousel-prev').on('click', function(){
      owl.trigger('prev.owl.carousel');
      return false;
    })

    $('.carousel-next').on('click', function(){
      owl.trigger('next.owl.carousel');
      return false;
    })


    $('.menu a, .scroll-to').on('click', function(){
      $('html, body').animate({
        scrollTop: $($(this).attr('href')).offset().top
      }, 1000);
      return false;
    });

    $('.newsletter-form').on('submit', function(){
      $.ajax({
                type: "POST",
                dataType: 'json',
                url: $(this).attr('action'),
                data: $('.newsletter-form').serialize(),
                success: function(data) {
                    // $('.newsletter-form').fadeOut(1000, function(){
                    //     $('.success-message').fadeIn(200);
                    // });
                    $('.newsletter-submit').fadeOut(1000);
                },
                error: function(data) {
                    $('.input-email').addClass('redBorder');
                }
            })
      return false;
    })

    $('.more-menu-btn').on('click', function(){
      $('.more-menu').toggle(1000);
      $(this).addClass('display-none');
      $('.less-menu-btn').removeClass('display-none');
      return false;
    });

    $('.less-menu-btn').on('click', function(){
      $('.more-menu').toggle(1000);
      $(this).addClass('display-none');
      $('.less-menu-btn').removeClass('display-none');
      return false;
    });
  }
}
$(function(){
  Site.init();
})
